.Home{
    min-height: 100vh;
    min-width: 100vh;
    background-color: #282c34;
    color: white;
  }

.icon{
    font-size: 100px;
}

.rowMC{
    display:flex;
}

.columnMC1{
    margin-right: 10vw;
}
  
.columnMC2{
    margin-right: 10vw;
}
  

  
@media only screen and (max-width: 600px) {

  
  .Home{
    min-height: 100vh;
    min-width: 10vh;
    background-color: #282c34;
    color: white;
    text-align: center;
  }

  .Home-header{
    width: 100vw;
    margin-left: 0;
  }

  .magnetSelector{
    font-size: medium;
}

.rowMC{
    display:flex;
}

.columnMC1{
    margin-right: 10vw;
}
  
.columnMC2{
    margin-right: 10vw;
}
  
}