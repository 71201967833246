body {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw; /* Ensure full viewport width */
    margin: 0;
  }
  
h1{
    text-align: center;
}

h3{
    display: inline-block;
    justify-content: space-evenly;
    width: 165px;
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 5px;
  }

.calendarGridContainer {
    display: inline;
    align-items: center;
    justify-content: space-around;
    padding-left: 5px;
  }



 /* Allow for scrolling */ 
.first,
.second,
.third,
.fourth,
.fifth,
.sixth{
    border: 1px solid #000; /* Add a border for visualization */
    width:175px;
    height:10vw;
    display: inline-block;
    margin: 0;
    padding-left: 0;
}

.row{
    margin: 0;
    margin-left: 5px;
}

.first:hover,
.second:hover,
.third:hover,
.fourth:hover,
.fifth:hover,
.sixth:hover{
    background-color: blue;
    color: white;
}

.eventDiv{
    margin: 0;
}

.Jeff{
    background-color: green;
    color: white;
}

.Sarah{
    background-color: skyblue;
}

.Millie{
    background-color: pink;
}

.important{
    background-color: red
}

.daySubBreak{
    border: 2px solid lightgray;
}

.dayHardBreak{
    border: solid 5px blue;
}

.dayContainer{
    display: flex;
}

.dayLeft, .dayRight{
    flex: 1;
    align-self: flex-start; /* Align each column at the top */
    padding: 10px; /* Add padding for spacing */
}

@media only screen and (max-width: 600px) {

    .CalendarContainer{
        width: 100vw; /* Ensure full viewport width */
        margin: 0;
      }
      
    h1{
        text-align: center;
    }
    
    h6{
        display: inline-block;
        justify-content: space-evenly;
        width: 12vw;
        margin-top: 25px;
        margin-left: 5px;
        font-size: medium       
    }
    
    .calendarGridContainer {
        display: inline;
        align-items: center;
        justify-content: space-around;
        padding-left: 5px;
      }  
    
    /* Allow for scrolling */ 
    .first,
    .second,
    .third,
    .fourth,
    .fifth,
    .sixth{
        border: 1px solid #000; /* Add a border for visualization */
        max-width:10vh;
        min-height:25vw;
        max-height: 100vw;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding-left: 0;
    }

    .first *,
    .second *,
    .third *,
    .fourth *,
    .fifth *,
    .sixth *{
        max-width:12.5vw;
        min-height:6vw;
        flex: 1;
        margin: 0;
        padding-left: 0;
    }
    
    .rowCal{
        margin: 0;
        margin-left: 5px;
    }
 
   
    .first:hover,
    .second:hover,
    .third:hover,
    .fourth:hover,
    .fifth:hover,
    .sixth:hover{
        background-color: blue;
        color: white;
    }
    
    .eventDiv{
        margin: 0;
    }
    
    .Jeff{
        background-color: green;
        color: white;
    }
    
    .Sarah{
        background-color: skyblue;
    }
    
    .Millie{
        background-color: pink;
    }
    
    .important{
        background-color: red
    }
    
    .daySubBreak{
        border: 2px solid lightgray;
    }
    
    .dayHardBreak{
        border: solid 5px blue;
    }
    
    .dayContainer{
        display: grid;
        width: 100vw;
    }

    .rowDiv{
        display: flex;
        width: 13vw;
        overflow: auto;
    }
    
  }