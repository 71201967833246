.error{
    color: red;
    font: bold;
}

button{
    background-color: darkblue;
    color: white;
    width: 15rem;
}

input{
    width: 300px;
    margin-top: 25px;
}

label{
    margin-top: 25px;
}

.AuthContainer {
    position: relative;
    z-index: 1;
    width: 1000px; /* Set the width of the container */
    margin: 0 auto; /* Center the container horizontally */
}

.backgroundImage {
    width: 1000px;
    height: 1000px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.Authorization {
    position: absolute;
    top: 650px;
    left: 520px;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center; /* Optionally center the text within the div */
}

@media only screen and (max-width: 600px) {

    html {
        overflow: auto;
    }
    .error{
        color: red;
        font: bold;
    }
    
    button{
        background-color: darkblue;
        color: white;
        width: 15rem;
    }
    
    input{
        width: 250px;
        margin-top: 25px;
    }
    
    label{
        margin-top: 25px;
    }
    
    .AuthContainer {
        position: relative;
        z-index: 1;
        width: 100vw;
        height: 100vh;
        margin: 0 auto; /* Center the container horizontally */
    }
    
    .backgroundImage {
        width: 800px;
        height: 800px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background-size: 250px;
    }
    
    .Authorization {
        position: absolute;
        top: 550px;
        left: 420px;
        transform: translate(-50%, -50%);
        z-index: 1;
        text-align: center; /* Optionally center the text within the div */
    }
  }

