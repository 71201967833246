.App{
  min-height: 100vh;
  min-width: 100vw;
  color: white;
  background-color: black;

}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid white;
  margin: 1em 0;
  padding: 0;
  }

.row {
  display: flex;
}

.column1 {
  margin-left: 25px;
  width: 50%;
}

.column2 {
  width: 10%;
}

.column3 {
  width: 30%;
}

input{
  width: 15rem;
}
@media only screen and (max-width: 600px) {

  .App{
    min-height: 100vh;
    width: 100vw;
    color: white;
    background-color: black;

  }



  .column1 {
    margin-left: 25px;
    width: 30%;

  }
  
  .column2 {
    visibility: hidden;
  }
  
  .column3 {
    width: 30%;
  }

}