.Shop-App{
  min-height: 100vh;
  min-width: 100vw;
  color: white;
  background-color: black;

}
.App-headerShop{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
  .listItemShop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75vw;
  }
  
  .titleShop,
  .categoryShop,
  .storeShop {
    flex: 1;
    text-align: left; /* Adjust alignment as needed */
    padding: 0 10px; /* Adjust padding as needed */
  }
  
  /* Optional: Add this to align icons to the right */
  .listItemShop > *:last-child,
  .listItemShop > *:nth-last-child(2) {
    margin-left: auto;
  }
  
  .rowShop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw; /* Ensure full viewport width */
    margin: 0;
  }
  
.h1Shop{
    text-align: center;
}

.h6Shop{
    display: inline-block;
    justify-content: space-evenly;
    width: 12vw;
    margin-top: 25px;
    margin-left: 5px;
    font-size: medium       
}

.rowDivShop{
    display: flex;
    width: 13vw;
    overflow: auto;
}
@media only screen and (max-width: 600px) {

  .Shop-App{
    min-height: 100vh;
    min-width: 100vw;
    color: white;
    background-color: black;

  }

  .App-headerShop{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }

  .listItemShop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75vw;
  }
  
  .titleShop,
  .categoryShop,
  .storeShop {
    flex: 1;
    text-align: left; /* Adjust alignment as needed */
    padding: 0 10px; /* Adjust padding as needed */
  }
  
  /* Optional: Add this to align icons to the right */
  .listItemShop > *:last-child,
  .listItemShop > *:nth-last-child(2) {
    margin-left: auto;
  }
  
  .rowShop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw; /* Ensure full viewport width */
    margin: 0;
  }
  
.h1Shop{
    text-align: center;
}

.h6Shop{
    display: inline-block;
    justify-content: space-evenly;
    width: 12vw;
    margin-top: 25px;
    margin-left: 5px;
    font-size: medium       
}

.rowDivShop{
    display: flex;
    width: 13vw;
    overflow: auto;
}


}