.Favorites-App{
    min-height: 100vh;
    min-width: 100vw;
    color: white;
    text-align: center;
    background-color: black;
  } 

  .Fav-Header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }

  .rowFav{
      display: flex;
      text-align: center;
      flex: 1 1 0px;
  }

  .columnF1{
    width: 25%;
  }

  .columnF2{
    width: 15%;
  }

  .columnF3{
    width: 60%;  
}
  .rowFav{
      display: flex;
      text-align: center;
      flex: 1 1 0px;
  }

  .columnFT1{
    width: 25%;
    margin-left: 2.25rem;
  }

  .columnFT2{
    width: 15%;
    margin-left: -.5rem

  }

  .columnFT3{
    width: 50%; 
    margin-left: 2rem; 
}

.line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid white;
    margin: 1em 0;
    padding: 0;
    }

.newFave{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  justify-items: center;
  flex-wrap:wrap;
}

.favSmall{
  min-width: 100px;
}

.favBig{
  min-width: 75vw;
  min-height: 10vh;
}

  @media only screen and (max-width: 600px) {
  
    .Favorites-App{
      min-height: 100vh;
      min-width: 100vw;
      color: white;
      background-color: black;

    }
  
    .Fav-Header{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
    }
  


  .line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid white;
    margin: 1em 0;
    padding: 0;
    }
  
    .rowFav{
      display: flex;
      text-align: center;
      flex: 1 1 0px;
  }

  .columnFT1{
    width: 25%;
    margin-right: 5px;
    margin-left: 2.5rem;
  }

  .columnFT2{
    width: 15%;
    margin-right: 10px;
    margin-left: .5rem;

  }

  .columnFT3{
    width: 50%;  
}

  .columnF1{
    width: 25%;
    margin-right: 5px;
  }

  .columnF2{
    width: 15%;
    margin-right: 10px;
  }

  .columnF3{
    width: 50%;  
}

.line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid white;
    margin: 1em 0;
    padding: 0;
    }

.newFave{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  justify-items: center;
  flex-wrap:wrap;
}

  }