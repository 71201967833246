.Home{
    min-height: 100vh;
    min-width: 100vh;
    color: black;
    background-color: transparent;
  }

  .HomeBackground {
    width: 75vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 12%;
    z-index: -1;
}

  .icon{
    height: 10vh;
    width: 8.5vh;
}

.title{
    font-size: larger;
}

.HomeHeader1{
  display: flex;
  position: absolute;
  left: 35%;
  margin-bottom: 5rem;
  width: 35vw;
  font-size: small;
}



.iconRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* or use space-between for even spacing */
    position: absolute;
    width: 35vw;
    left: 35%;
    top: 20%;
  }
  
.iconContainer {
text-align: center; /* Optional: Center the content inside each container */
margin: 10px; /* Optional: Adjust the margin between containers */
}
  
@media only screen and (max-width: 600px) {

 
  .Home{
    min-height: 100vh;
    min-width: 10vh;
    color: black;
    text-align: center;
  }

  .HomeHeader1{
    width: 100vw;
    margin-left: 0;
    position: absolute;
    left: 0px;
    margin-bottom: 10px;
  }

.icon{
    height: 10vh;
    width: 10vw
}

p.title {
  text-align: center;
}

.iconRow {

    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;

      display: flex;
      flex-wrap: wrap;
      justify-content: space-around; /* or use space-between for even spacing */
      position: absolute;
      top: 15%;
      left: 35%;
      width: 25vw;
  }
  
.iconContainer {
text-align: center; /* Optional: Center the content inside each container */
margin: 0;
}

.icon{
  height: 8vh;
  width: 10vw;
  margin-bottom: 0;
  margin-top: 0;
}

}